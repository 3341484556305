import { wrapGrid } from "animate-css-grid";
// import { gsap } from 'gsap'

$(document).ready(() => {
  const hamb = document.querySelector("#hamb");
  const mainNav = document.querySelector("#main-nav");
  const overlay = document.querySelector("#overlay");
  const closeMenuBtn = document.querySelector("#close-menu");
  const searchBtn = document.querySelector(".search-btn");
  // const closeSearchBtn = document.querySelector('#close-search-btn')
  const searchModal = document.querySelector("#search-modal");
  const viewportWidth = $(window).width();
  const headerSearchBtn = document.querySelector("#hdr-search-btn");
  const headerSearch = document.querySelector("#hdr-search");
  const highlightBtn = document.querySelector("header nav .highlight");
  // for hiding/showing header on scroll
  const hdr = document.querySelector("header .inner");
  let lastScroll = window?.pageYOffset || 0;

  // floating labels for gform inputs with top label
  $(
    "form .top_label .gfield .ginput_container input, form .top_label .gfield .ginput_container textarea"
  ).on("focus", function () {
    $(this).parent().parent(".gfield").addClass("active");
  });

  $(
    "form .top_label .gfield .ginput_container input, form .top_label .gfield .ginput_container textarea"
  ).on("blur", function () {
    if ($(this).val().length == 0) {
      $(this).parent().parent(".gfield").removeClass("active");
    }
  });

  hamb.addEventListener(
    "click",
    function (e) {
      e.stopPropagation();
      closeSearch();
      openMenu();
    },
    false
  );

  closeMenuBtn.addEventListener(
    "click",
    function (e) {
      e.stopPropagation();
      closeSearch();
      closeMenu();
    },
    false
  );

  searchBtn.addEventListener(
    "click",
    function (e) {
      e.stopPropagation();
      closeMenu();

      if (searchModal.classList.contains("hidden")) {
        openSearch();
      } else {
        closeSearch();
      }
    },
    false
  );

  headerSearchBtn.addEventListener("click", function (e) {
    e.stopPropagation();
    headerSearch.classList.remove("hidden");

    document.body.addEventListener("click", closeHeaderSearch);
  });

  function closeHeaderSearch(el) {
    const element = el.target;

    if (!element.closest("#hdr-search") && $("#hdr-search").is(":visible")) {
      headerSearch.classList.add("hidden");
      document.body.removeEventListener("click", closeHeaderSearch);
    }
  }

  // opens the search
  function openSearch() {
    // console.log('openning search!!')
    toggleBodyFreeze("fixed");
    searchBtn.classList.add("active");
    searchModal.classList.remove("hidden");
  }

  // closes the search
  function closeSearch() {
    toggleBodyFreeze("inherit");
    searchBtn.classList.remove("active");
    searchModal.classList.add("hidden");
  }

  // opens main nav
  function openMenu() {
    mainNav.classList.remove("translate-x-full");
    overlay.classList.remove("hidden");
    toggleBodyFreeze("fixed");
    hamb.classList.add("hidden");
    closeMenuBtn.classList.remove("hidden");
  }

  // closes main nav
  function closeMenu() {
    mainNav.classList.add("translate-x-full");
    overlay.classList.add("hidden");
    toggleBodyFreeze("inherit");
    hamb.classList.remove("hidden");
    closeMenuBtn.classList.add("hidden");
  }

  function toggleBodyFreeze(position) {
    document.querySelector("body").style.position = position;
  }

  // animate on scroll library
  AOS.init({ once: true });

  var dropdowns = document.querySelectorAll(".dropdown");

  $("body").click(function (e) {
    //closeSearch();
    closeAllDropDowns();
  });

  // document.querySelector('header').addEventListener('mouseenter', function(){
  //   hdr.classList.remove("-translate-y-full");
  // })
  function handleHeaderEffect() {
    //console.log(`handleHeaderEffect`, window.pageYOffset);
    let scrolly = window.pageYOffset;
    if (scrolly > 300) {
      // hdr.classList.add('shadow-2xl')
      if (window.pageYOffset > lastScroll) {
        hdr.classList.add("-translate-y-150");
      } else {
        hdr.classList.remove("-translate-y-150");
      }
    } else {
      //hdr.classList.remove('-translate-y-full','shadow-2xl')
      hdr.classList.remove("-translate-y-150");
    }
    lastScroll = scrolly;
  }

  $(window).scroll(function () {
    handleHeaderEffect();
    if (viewportWidth <= 1199) {
      return;
    } else {
      closeSearch();
      closeAllDropDowns();
     // blurBehindHeader();
    }
  });


  function blurBehindHeader() {
    if (window.scrollY < 300) {
      document.querySelector("header").style.backdropFilter = "blur(0px)";
    } else {
      document.querySelector("header").style.backdropFilter = "blur(4px)";
    }
  }

  // $('#searchHolderDesktop button').click(function (e) {
  //     e.stopPropagation()
  //     openSearch();
  // });

  // $('#searchHolderDesktop form').click(function (e) {
  //     e.stopPropagation()
  //     //openSearch();
  // });

  // function openSearch() {
  //     $('#searchHolderDesktop').addClass('active')
  //     $('#searchHolderDesktop form input.search-field').focus()
  //     $('.topnav a, .topnav .pipe').each(function () {
  //         $(this).addClass('hidden')
  //     })
  // }

  // function closeSearch() {
  //     $('#searchHolderDesktop').removeClass('active');
  //     $('#searchHolderDesktop form input.search-field').blur()
  //     $('.topnav a, .topnav .pipe').each(function () {
  //         $(this).removeClass('hidden')
  //     })
  // }

  function closeAllDropDowns() {
    $("header").removeClass("active");
    $(".underlay").removeClass("active");

    $(dropdowns).each(function () {
      $(this).removeClass("open");

      const caret = $(".caret", this).first();

      if (caret.hasClass("rotate-up")) {
        caret.removeClass("rotate-up").addClass("rotate-down");
      }
    });
  }

  // on resize, reset this wiewport width

  if (viewportWidth <= 1199) {
    setWpAdminBarOnMobile();

    $("header .dropdown-menu-open").click(function (e) {
      e.preventDefault();
      e.stopPropagation();

      const parentLink = $(this).parents(".dropdown");

      console.log(parentLink[0], parentLink[1]);

      if (parentLink.length >= 2) {
        parentLink[0].classList.add("submenu");

        if ($(parentLink[0]).hasClass("open")) {
          $(parentLink[0]).removeClass("open");
        } else {
          $(parentLink[0]).addClass("open");
        }
      } else {
        if (parentLink.hasClass("open")) {
          parentLink.removeClass("open");
        } else {
          closeAllDropDowns();
          parentLink.addClass("open");
        }
      }
    });
  } else {
    $(".navbar .menu-item.menu-item-has-children").on(
      "mouseenter",
      function (e) {
        e.stopPropagation();
        e.preventDefault();
        closeAllDropDowns();
        $("header").addClass("active");
        $(".underlay").addClass("active");
        $(this).closest("div.menu-item-has-children").addClass("open");

        animateCaret(this.closest("div.menu-item-has-children"));
        showSubmenu(this.closest("div.menu-item-has-children"));
      }
    );
  }

  function showSubmenu(el) {
    const submenu = $(".menu-item-has-children", el);
    const dropdown = $(".dropdown-menu.has-children");

    submenu
      .on("mouseover", function () {
        dropdown.addClass("submenu");
        dropdown.show();
      })
      .on("mouseout", function () {
        dropdown.removeClass("submenu");
        dropdown.hide();
      });
  }

  function animateCaret(el) {
    const caret = $(el).find(".caret").first();

    if (caret.hasClass("rotate-down")) {
      caret.removeClass("rotate-down");
    }
    caret.addClass("rotate-up");
  }

  function setWpAdminBarOnMobile() {
    $("#wpadminbar").css("position", "fixed");
  }

  // Hover Grid
  // const options = {
  //   // stagger: 100,
  //   duration: 800,
  //   easing: 'linear',
  //   onStart: (els) => {
  //   },
  //   onEnd: (els) => {
  //   },
  // }
  // const grid = document.querySelector(".grid");
  // const { unwrapGrid, forceGridAnimation } = wrapGrid(grid, options);

  // let prevGridNo = 1;

  // $('.grid .box').each(function (index) {
  //   let newIndex = index + 1;

  //   if (newIndex !== 1) {
  //     hideBoxDetails(newIndex);
  //   }
  //   $(`#box-1 .readmore-2`).show();

  // });

  // function hideBoxDetails(boxno) {
  //   $(`#box-${boxno} h5`).removeClass('animate__fadeIn');
  //   $(`#box-${boxno} .paragraph`).addClass('animate__fadeOut');
  //   $(`#box-${boxno} .bg`).addClass('animate__fadeOut opacity-1');
  //   $(`#box-${boxno} .readmore-2`).hide();
  // }

  // function activate(gridno) {

  //   $('.masonry').removeClass(`active-${prevGridNo}`);
  //   $('.masonry').addClass(`active-${gridno}`);

  //   $('.grid .box').each(function (index) {
  //     let newIndex = index + 1;
  //     hideBoxDetails(newIndex);
  //   });

  //   setTimeout(() => {
  //     $(`#box-${gridno} h5`).addClass('animate__fadeIn');
  //     $(`#box-${gridno} .paragraph`).removeClass('animate__fadeOut');
  //     $(`#box-${gridno} .bg`).removeClass('animate__fadeOut opacity-1');
  //   }, 400);

  //   setTimeout(() => {
  //     $(`#box-${gridno} .readmore-2`).show();
  //   }, 800);

  //   forceGridAnimation();
  //   prevGridNo = gridno;
  // }

  // function deactivate(gridno) {
  //   $(`#box-${gridno} .readmore-2`).hide();
  //   unwrapGrid();
  // }

  // const otherOptions = {
  //   sensitivity: 1, //default: 6
  //   interval: 500, //default: 100
  //   timeout: 1000, //default: 0
  // }

  // $('#box-1').hoverIntent({
  //   over: () => {
  //     const isActive = $('.masonry').hasClass('active-1');
  //     if (!isActive) {
  //       activate(1);
  //     }
  //   }, out: () => deactivate(1), otherOptions
  // });
  // $('#box-2').hoverIntent({
  //   over: () => {
  //     const isActive = $('.masonry').hasClass('active-2');
  //     if (!isActive) {
  //       activate(2);
  //     }
  //   }, out: () => deactivate(2), otherOptions
  // });
  // $('#box-3').hoverIntent({
  //   over: () => {
  //     const isActive = $('.masonry').hasClass('active-3');
  //     if (!isActive) {
  //       activate(3);
  //     }
  //   }, out: () => deactivate(3), otherOptions
  // });
  // $('#box-4').hoverIntent({
  //   over: () => {
  //     const isActive = $('.masonry').hasClass('active-4');
  //     if (!isActive) {
  //       activate(4);
  //     }
  //   }, out: () => deactivate(4), otherOptions
  // });
  // $('#box-5').hoverIntent({
  //   over: () => {
  //     const isActive = $('.masonry').hasClass('active-5');
  //     if (!isActive) {
  //       activate(5);
  //     }
  //   }, out: () => deactivate(5), otherOptions
  // });
  // $('#box-6').hoverIntent({
  //   over: () => {
  //     const isActive = $('.masonry').hasClass('active-6');
  //     if (!isActive) {
  //       activate(6);
  //     }
  //   }, out: () => deactivate(6), otherOptions
  // });

  // $('.masonry').on('mouseleave', function () {
  //   activate(1);
  // });
});
